import React, { useContext, useEffect, useState } from "react";
import image from "./image.png";
import { DataContext } from "../../context/DataContext";
import { urlFor } from "../../sanityCli";

export const HomeAnnouncementSec = () => {
  const { exams, loading } = useContext(DataContext);
  const [currentQuestions, setCurrentQuestions] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Default to 9 items per page

  // Adjust items per page based on screen width
  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth < 768) {
        setItemsPerPage(6); // Mobile view: 6 items per page
      } else {
        setItemsPerPage(9); // Desktop view: 9 items per page
      }
    };

    window.addEventListener("resize", updateItemsPerPage);
    updateItemsPerPage(); // Initial check

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  if (loading) return <></>;

  const filterQuestions = (title = "Prelims") => {
    var qstns = exams[0].subExams.find((x) => x.title === title).questions;
    console.log("Questions", qstns)
    var uniqueQuestions = qstns.items.filter((q, index, self) => 
      index === self.findIndex((item) => item.title === q.title)
    );
    setCurrentQuestions(
      uniqueQuestions
    );
    console.log("Questions",  exams[0].subExams.find((x) => x.title === title).questions)

    setCurrentPage(1); // Reset to first page when filtering
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentQuestions?.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil((currentQuestions?.items?.length || 0) / itemsPerPage);

  return (
    <div className="w-full home-exam-section flex flex-col items-center justify-center mt-6 lg:mt-6 px-[4vw]">
      {currentQuestions == null && filterQuestions("Prelims")}
      <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-6">
        {exams && (
          <button className="bgColor text-[#FF2C45] text-base lg:text-[1.5vw] font-semibold border  border-[#FF2C45] px-4 py-1 lg:py-2 rounded-full">
            {exams[0].homeExamHeader}
          </button>
        )}
        {exams && (
          <h1 className="text-[#007fff] text-base lg:text-[3vw] font-bold">
            {exams[0].homeExamSubHeader.split(" ").slice(0, 5).join(" ") + " "}
            <span className="text-[#FF2C45]">
              {exams[0].homeExamSubHeader.split(" ").slice(5).join(" ")}
            </span>
          </h1>
        )}
        <span className="flex items-center justify-center gap-2 lg:gap-8">
          {exams &&
            exams[0].subExams.map((x) => (
              <button
                key={x.title}
                className="bg-[#007fff] text-[#fff] text-[10px] lg:text-[1.25vw] w-fit   font-semibold lg:w-[6.5vw] lg:h-[6.5vw]  shadow-2xl hover:bg-[#fff] hover:text-[#030260] px-4 py-2 flex items-center justify-center rounded-full"
                onClick={() => filterQuestions(`${x.title}`)}
              >
               <span>{x.title}</span> 
              </button>
            ))}
        </span>
      </div>

      <div className="w-full note-box-container my-4 lg:my-12 flex items-center justify-between flex-wrap">
        {currentItems &&
          currentItems.map((y, j) => (
            <a href={`${exams[0].examName}/${exams[0].subExams[0].title}/${y.title}/topics`}
              key={j}
              className="question-box w-[48%] lg:w-[23%] lg:min-h-[20vh] bg-[#FFF] relative mb-6 lg:p-2 p-2 rounded-lg border border-gray-300 flex flex-col items-center justify-evenly space-y-2 lg:space-y-2
              transform transition-transform duration-500 ease-in-out hover:scale-95 shadow-lg hover:shadow-xl" // Hover and transition effect
              style={{ animation: `fadeIn 0.5s ease-in-out ${(j + 1) * 0.1}s` }} // Staggered animation
            >
              {y.icon && <img src={urlFor(y.icon)} alt="/" className="w-24 lg:w-[6.5vw] rounded-full" />}
              <h1 className="text-[#030260] text-xs text-center  lg:text-[1.25vw] lg:leading-[2.5vw] font-semibold ">
                {y.title}
              </h1>
            </a>
          ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="pagination flex justify-center my-12 space-x-2">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-3 py-1 rounded-full ${currentPage === index + 1 ? "bg-[#007fff] text-white" : "bg-gray-200 text-gray-600"
                }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// CSS animation
const fadeIn = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
`;
document.head.insertAdjacentHTML("beforeend", `<style>${fadeIn}</style>`);



// import React, { useState, useEffect } from "react";

// const carouselItems = [
//   {
//     id: 1,
//     title: "Latest/Upcoming courses and exam notifications for IAS",
//   },
//   {
//     id: 2,
//     title: "New IAS preparatory courses available now",
//   },
//   {
//     id: 3,
//     title: "Important updates on IAS examination pattern",
//   },
//   {
//     id: 4,
//     title: "Join our IAS coaching program this season",
//   },
//   {
//     id: 5,
//     title: "Upcoming webinars on IAS preparation strategies",
//   },
//   {
//     id: 6,
//     title: "Special discounts on IAS preparatory courses",
//   },
// ];

// const colors = ["bg-red-500", "bg-sky-800", "bg-blue-500", "bg-blue-600", "bg-yellow-500", "bg-purple-500"];

// const getRandomColor = () => {
//   return colors[Math.floor(Math.random() * colors.length)];
// };

// export const HomeAnnouncementSec = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
//     }, 3000); // Slide every 3 seconds

//     return () => clearInterval(timer);
//   }, []);

//   const getVisibleItems = () => {
//     const items = [];
//     for (let i = 0; i < 3; i++) {
//       items.push(carouselItems[(currentIndex + i) % carouselItems.length]);
//     }
//     return items;
//   };

//   return (
//     <div className="home-announcement py-[5vw] px-[4vw] flex flex-col items-start space-y-4 bg-gray-100">
//       <div>
//         <h1 className="text-[2vw] font-serif font-semibold text-black my-8">
//           Latest Update on Wisdom IAS
//         </h1>
//       </div>
//       <div className="my-12">
//         <p className="text-gray-800 font-semibold text-[1.75vw]">Announcement</p>
//         <p className="text-[1.25vw] font-normal text-gray-800 my-4">
//           Latest/Upcoming courses and exam notifications for IAS and State PCS Examinations.
//         </p>
//       </div>
//       <div className="announcement-carousel w-full flex items-center overflow-hidden relative">
//         <div
//           className="w-full flex items-start gap-8 transition-transform duration-500 ease-in-out py-12 "
//           style={{ transform: `translateX(-${(currentIndex % carouselItems.length) * (100 / 3)}%)` }}
//         >
//           {[...carouselItems, ...carouselItems].map((item, index) => (
//             <div
//               key={index}
//               className={`carousel-box w-1/3 flex-shrink-0 flex flex-col items-center space-y-4 p-8 rounded-md ${getRandomColor()}`}
//             >
//               <h1 className="text-slate-50 font-bold font-sans text-center text-[1.5vw]">
//                 {item.title}
//               </h1>
//               <button className="bg-slate-50 text-slate-900 text-[1.25vw] text-center font-bold px-4 py-1 rounded-lg">
//                 More details
//               </button>
//             </div>
//           ))}
//         </div>
//         <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
//           {[...Array(2)].map((_, index) => (
//             <button
//               key={index}
//               className={`w-3 h-3 rounded-full ${index === (currentIndex % 2) ? 'bg-gray-800' : 'bg-gray-400'}`}
//               onClick={() => setCurrentIndex(index)}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };
