import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams, Link, useNavigate } from "react-router-dom";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import Header from "../../../header/Header";
import {
  HeroHeaderSyllabus,
  HeaderButton,
  HeroHeader,
} from "../../HeroHeaderSyllabus";
import { urlFor } from "../../../../sanityCli";

export const SubjectNotes = () => {
  const { exams, notes, loading } = useContext(DataContext);
  const { subjectName } = useParams();
  const navigate = useNavigate();

  if (loading) return <></>;

  const subjectNotes = notes[0].subjects.find(
    (x) => x.subjectName == subjectName
  );
  const syllabus = exams[0].subExams[0].syllabus;

  var headerButtons = [
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} Questions`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/questions`
    ),
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} PYQs`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/pyqs`
    ),
  ];
  const header = new HeroHeader(
    headerButtons,
    `${subjectName}`,
    `${syllabus.author}`,
    `${syllabus.date}`
  );

  console.log("sub notes", subjectNotes);

  return (
    <>
      <Header />
      <div className="mt-[12vw]">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <div className="notes-subject-detail px-4 py-[5vw] flex flex-col items-center">
        <p className="text-[#007fff] text-xl lg:text-4xl font-semibold">
          {subjectName} Notes
        </p>
        <div className="w-full flex flex-wrap px-[4vw] items-center justify-between">
          {subjectNotes &&
            subjectNotes.topics.map((item, itemIndex) => (
              <div
                onClick={() =>
                  navigate(`/notes/${subjectName}/${item.header}`, {
                    state: { subjectNotes, subjectName },
                  })
                }
                className="notes-box w-full sm:w-[45%] lg:w-[28%] flex items-center gap-4 space-y-2 bg-white rounded-lg mt-2  mb-8 px-4  py-2 transition-all duration-500 ease-in-out shadow-xl hover:shadow-2xl hover:transform hover:-translate-y-1"
              >
                <div className="rounded-full flex justify-center items-center"> 
                  <img src={urlFor(subjectNotes.icon)} alt="/" className="w-20 sm:w-[6vw]"/>
                </div>
                <div className="flex flex-col items-start space-y-2 lg:space-y-2">
                  <p className="text-[#2d2d2d] opacity-95 text-lg lg:text-2xl font-semibold ">
                    {subjectName}
                  </p>
                  <p className="text-gray-800  text-base lg:text-xl font-medium">
                    {item.header}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
