// src/Footer.js
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../header/Logo.png"; // Adjust the path as needed
import { DataContext } from "../../context/DataContext";
import { urlFor } from "../../sanityCli";

export const Footer = () => {
  const { headerFooter, loading } = useContext(DataContext);
  if (loading) <></>;

  return (
    <>
      <div className="footer w-full bg-[#007fff] text-slate-50 px-[5vw] py-[2vw] flex lg:flex-row flex-col items-start lg:items-start justify-start gap-8">
        <div className="footer-left w-full lg:w-[30%] flex flex-col items-start justify-start space-y-4 lg:space-y-2">
          <Link to="/">
            {headerFooter && (
              <img
                src={urlFor(headerFooter[0].footerLogo) || logo}
                alt="footer-logo"
                className="w-40 md:w-40 lg:w-[12vw]"
              />
            )}
          </Link>
          {headerFooter && (
            <p className="text-sm lg:text-lg lg:leading-10 text-slate-200 font-normal capitalize pr-12">
              {headerFooter[0].logoDescription}
            </p>
          )}
        </div>

        <div className="lg:w-[20%] w-full flex flex-col items-start justify-start space-y-2 lg:space-y-4 lg:pb-0 pb-4">
          <p className="lg:text-[2vw] text-xl font-semibold text-slate-200">
            Quick लिंक
          </p>
          <ul className="flex flex-col items-start space-y-2 text-sm lg:text-lg font-medium">
            {headerFooter &&
              headerFooter[0].quickLinks.map((link, index) => (
                <li
                  key={index}
                  className="hover:scale-105 transition-colors duration-300 cursor-pointer"
                >
                  <a
                    href={link.url}
                    className="text-sm lg:text-lg text-slate-200 font-normal capitalize"
                  >
                    {link.text}
                  </a>
                </li>
              ))}
          </ul>
        </div>

        <div className="flex lg:w-[20%] w-full flex-col items-start justify-center lg:items-start lg:justify-start space-y-4">
          <h1 className="lg:text-[2vw] text-xl font-semibold text-slate-200">
            Join us on
          </h1>
          {headerFooter &&
            headerFooter[0].socialMedia.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <span className="lg:w-6 lg:h-6 w-6 h-6 bg-white flex items-center justify-center border border-slate-50 rounded-full hover:scale-110 transition-transform duration-300 cursor-pointer">
                  {item && item.url && (
                    <a
                      href={item.url}
                      className="flex flex-col items-start space-y-4"
                    >
                      <img
                        src={urlFor(item.icon)}
                        alt={`social-icon-${index + 1}`}
                        className="lg:w-3 lg:h-3 w-3 h-3"
                      />
                    </a>
                  )}
                </span>
                {item && item.text && (
                  <a href={item.url} className="text-sm lg:text-lg text-slate-200 font-normal capitalize">
                    {item.text}
                  </a>
                )}
              </div>
            ))}
        </div>

        <div className="flex lg:w-[20%] w-full flex-col items-start justify-center space-y-4">
          <h1 className="lg:text-[2vw] text-xl font-semibold text-slate-200">
            Contact
          </h1>
          {headerFooter &&
            headerFooter[0].contact.map((item, index) => (
              <div key={index} className="flex items-start gap-1">
                {item && item.icon && (
                  <span
                    className={`${index === 0 ? "lg:w-16 lg:h-12 w-12 h-12 mt-0" : "lg:w-8 lg:h-8 w-5 h-5 mt-0"}`}
                  >
                    <img src={urlFor(item.icon)} alt="/" />
                  </span>
                )}
                {item && item.text && (
                  <span className="text-sm lg:text-lg text-slate-200 font-normal">
                    {item.text}
                  </span>
                )}
              </div>
            ))}
        </div>
      </div>

      <div className="bg-[#002fff] p-5 lg:pt-4 lg:pb-6 flex lg:flex-row flex-col space-y-4 items-center justify-center">
        {headerFooter && (
          <strong className="text-slate-50 font-normal text-center text-sm">
            {headerFooter[0].copywriteText}
          </strong>
        )}
      </div>
    </>
  );
};

// import React from "react";
// import { Link } from "react-router-dom";
// import icon1 from "../../assets/Home/icon1.svg";
// import icon2 from "../../assets/Home/icon2.svg";
// import icon3 from "../../assets/Home/icon3.svg";
// import icon4 from "../../assets/Home/icon4.svg";
// import icon5 from "../../assets/Home/icon5.svg";
// import icon6 from "../../assets/Home/icon6.svg";
// import logo from '../header/Logo.png'

// const footerData = {

//   quickLinks: [
//     { text: "होम", url: "/" },
//     { text: "प्रश्न पत्र", url: "/questionpaper" },
//     { text: "ईबुक स्टोर", url: "/ebooks" },
//     { text: "ब्लॉग", url: "/blog" },
//     { text: "About", url: "/about/us" },
//     { text: "contact", url: "/contact" },
//     { text: "privacy Policy", url: "/privacyPolicy" },
//     { text: "Terms & Services", url: "/termsServices" },
//   ],

//   socialMedia: [

//     { text: "Facebook", icon: icon4 },
//     { text: "X", icon: icon5 },
//     { text: "Linkedin", icon: icon6 },
//   ],

//   contact: [
//     { icon: icon3,  text: "53/1, Upper Ground Floor, Bada Bazar Road, Old Rajinder Nagar, New Delhi -110060" },
//     { icon: icon1,  text: "+91 91813 23 2309" },
//     { icon: icon2,  text: "contact@ajayanurag.in" },
//   ],

// };

// export const Footer = () => {
//   return (
//     <>
//       <div className="footer w-full bg-[#007fff] text-slate-50  px-[5vw] py-[2vw] flex lg:flex-row flex-col items-start lg:items-start justify-start gap-8">
//         <div className="footer-left w-full lg:w-[30%] flex flex-col items-start justify-start space-y-4 lg:space-y-2">
//           <Link to="/">
//             <img src={logo} alt="footer-logo" className=" w-20 md:w-24 lg:w-[12vw]" />/*footerLogo will be use here  */
//           </Link>
//           <p className="text-sm lg:text-lg lg:leading-10 text-slate-200 font-normal capitalize pr-12">
//             Learning is the process of acquiring of new or modifying existing
//             Knowledge, behaivour skills, values of prefrences
//           </p>/* logoDescription will be use here  */
//         </div>

//         <div className="lg:w-[20%] w-full flex flex-col items-start justify-start space-y-2 lg:space-y-4 lg:pb-0 pb-4">
//           <p className=" lg:text-[2vw] text-xl font-semibold text-slate-200">
//             Quick लिंक
//           </p>
//           <ul className="flex flex-col items-start space-y-2 text-sm lg:text-lg font-medium">
//             {footerData.quickLinks.map((link, index) => (
//               <li
//                 key={index}
//                 className="hover:scale-105 transition-colors duration-300 cursor-pointer"
//               >
//                 <a
//                   href={link.url}
//                   className="text-sm lg:text-lg  text-slate-200 font-normal capitalize"
//                 >
//                   {link.text}
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>

//         <div className="flex lg:w-[20%] w-full flex-col items-start justify-center lg:items-start lg:justify-start space-y-4">
//           <h1 className=" lg:text-[2vw] text-xl font-semibold text-slate-200">
//             Join us on
//           </h1>

//             {footerData.socialMedia.map((item, index) => (
//               <div className="flex items-center gap-2">
//                 <span className="lg:w-6 lg:h-6 w-6 h-6 bg-white flex items-center justify-center  border border-slate-50 rounded-full hover:scale-110 transition-transform duration-300 cursor-pointer">
//                 <a
//             href="https://www.linkedin.com/in/ajay-anurag-5111707a?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in"
//             className="flex flex-col items-start space-y-4"
//           >
//                   <img
//                     key={index}
//                     src={item.icon}
//                     alt={`social-icon-${index + 1}`}
//                     className="lg:w-3 lg:h-3 w-3 h-3 "
//                   />
//                   </a>
//                 </span>
//                 <span className="text-sm lg:text-lg  text-slate-200 font-normal capitalize">{item.text}</span>
//               </div>
//             ))}

//         </div>
//         <div className="flex lg:w-[20%] w-full flex-col items-start justify-center space-y-4">
//           <h1 className="lg:text-[2vw] text-xl font-semibold text-slate-200">
//             Contact
//           </h1>
//           {
//             footerData.contact.map((item, index) => (
//               <div key={index} className="flex items-start gap-1">
//                 <span className={`${index === 0 ? 'lg:w-16 lg:h-16 w-12 h-12 mt-0' : 'lg:w-8 lg:h-8 w-5 h-5 mt-0'}`}><img src={item.icon} alt='/' className=""/></span>
//                 <span className="text-sm lg:text-lg  text-slate-200 font-normal">
//                    {item.text}
//                 </span>
//                 </div>
//             ))
//           }

//         </div>
//       </div>
//       <div className="bg-[#002fff] p-5  lg:pt-4 lg:pb-6 flex lg:flex-row flex-col space-y-4 items-center justify-center">
//         <strong className="text-slate-50 font-normal text-center text-sm">
//           © 2024 डॉ. अजय अनुराग. सर्वाधिकार सुरक्षित। /*copywriteText will be use here */
//         </strong>
//       </div>
//     </>
//   );
// };
