import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { HeroHeaderSyllabus, HeaderButton, HeroHeader } from "../../HeroHeaderSyllabus";
import { urlFor } from "../../../../sanityCli";

export const TopicDetail = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName, subject } = useParams();
  const navigate = useNavigate();
  if (loading) {
    return <div>Loading...</div>;
  }
  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const syllabus = subExam.syllabus
  const questions = subExam.questions;
  const subjectQuestions = questions.items.filter(
    (x) => (x._type === "nSubject" &&
    x.title == subject)
  );
  const fullQuestions = questions.items.filter((x) => (x._type === "full" && x.title == subject));
  const subjectTitles = [...new Set([...subjectQuestions.map(q => q.topic)])];
  const fullTitles = [...new Set([...fullQuestions.map((question) => question.topic)])];


  console.log("subject questions", subjectQuestions);
  console.log("subject title", subjectTitles);

  var headerButtons = [
    new HeaderButton(
      `${examName} ${subExamName} notes`,
      `/notes`
    ),
    new HeaderButton(
      `${examName} ${subExamName} PYQs`,
      `/${examName}/${subExamName}/pyqs`
    ),
  ];
  var header = 
    new HeroHeader(
      headerButtons,
      examName + " " + subExamName + " Questions",
      syllabus.author,
    )

  return (
    <>

      <Header />
      <div className="mt-20">
      <HeroHeaderSyllabus props = {header}/>
      </div>
      <div>
        <section className="w-full px-[4vw] py-4vw] flex flex-col items-center space-y-0 pb-12 lg:py-20">
            <h1 className="text-[#007fff] text-center lg:text-[2.25vw] lg:leading-[5vw] text-lg font-semibold">
              {examName} {subExamName} {subject} Topic wise questions
            </h1>
            <div className="w-full flex flex-wrap items-start justify-between pb-1">
              {subjectTitles.map((x, i) => {
                return (
                  <div
                    key={i}
                    onClick={() =>
                      navigate(`/${examName}/${subExamName}/${subject}/${x}/questions`, {
                        state: { questions, x },
                      })
                    }
                    className="w-full sm:w-[46%] lg:w-[30%] bg-white rounded-lg shadow-lg px-4 py-2 flex items-center gap-2 mt-6 transition-all duration-500 ease-in-out  hover:shadow-2xl hover:transform hover:-translate-y-1"
                  >
                    <span className=" rounded-full flex items-center justify-center">
                      <img src={urlFor(subjectQuestions.find((z) => z.topic == x).icon)} alt="/icon" className="w-20 lg:w-[6vw]" />
                    </span>
                    <span className="flex flex-col items-start lg:space-y-2">
                      <strong className="text-xl">{x}</strong>
                      <span className="text-base">{subject}</span>
                    </span>
                  </div>
                );
              })}
            </div>
        </section>
      </div>
      <div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
