import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { urlFor } from "../../../../sanityCli";
import BlockContent from "@sanity/block-content-to-react";
import Header from "../../../header/Header";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import { ListContent } from "../../../reusableComponent/ListContent";
import { PrelimsContentBox } from "../../../reusableComponent/PrelimsContentBox";
import Table2 from "../../../reusableComponent/Table2";
import { DataContext } from "../../../../context/DataContext";
import {
  HeroHeaderSyllabus,
  HeaderButton,
  HeroHeader,
} from "../../HeroHeaderSyllabus";

export const NotesDetailPage = () => {
  const { exams, notes, ebooks, loading } = useContext(DataContext);
  const location = useLocation();
  const { subjectName, topic } = useParams();
  console.log(subjectName);

  if (loading) {
    return <></>;
  }
  const syllabus = exams[0].subExams[0].syllabus;
  console.log("ebooks", ebooks);
  var currentTopic = location.state.subjectNotes.topics.find(
    (x) => x.header == topic
  );
  var topicContents = currentTopic.topicContent;
  console.log("topic", topicContents);

  var headerButtons = [
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} Questions`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/questions`
    ),
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} PYQs`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/pyqs`
    ),
  ];
  const header = new HeroHeader(
    headerButtons,
    `${subjectName} - ${topic}`,
    syllabus.author,
    syllabus.date
  );
  return (
    <>
      <Header />
      <div className="">{header && <HeroHeaderSyllabus props={header} />}</div>
      <main className="flex items-start gap-5">
        <div className="flex flex-col items-center justify-center bg-[#fff] m-2 p-2 lg:m-8 lg:p-4 lg:w-3/4">
          {topicContents.map((x) =>
            x.items.map((y) => {
              return (
                y._type == "listContent" && <ListContent listContents={[y]} />
              );
            })
          )}
          {topicContents.map((x) =>
            x.items.map((y) => {
              return (
                y._type == "ContentBoxWithImageCheckbox" && (
                  <PrelimsContentBox contentData={[y]} />
                )
              );
            })
          )}
          {topicContents.map((x) =>
            x.items.map((y) => {
              return (
                y._type == "tableData" && <Table2 title={y.title} data={y} />
              );
            })
          )}
          <div>
            {topicContents.map((x) =>
              x.images.map((x) => {
                return <img src={urlFor(x)} className="w-full my-4"></img>;
              })
            )}
          </div>
        </div>
        <div className="w-1/4 lg:hidden">

        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
