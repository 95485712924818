
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import BlockContent from "@sanity/block-content-to-react";

export const HomePracticeSec = () => {
  const { practiceQuestion, loading } = useContext(DataContext);
  console.log('practiceQuestion on homepage', practiceQuestion[0].questionAns)
  console.log('practiceQuestion on homepage', practiceQuestion[0].questionAns)
  const items = practiceQuestion[0].questionAns.map((item) => {
    return item;
  })
  console.log('items', items)
  const navigate = useNavigate();

  if (loading) return <div>Loading...</div>;

  return (
    <div className="practice-question-section flex flex-col items-center justify-center lg:mb-4 px-[4vw]">
      {practiceQuestion && (
        <h1 className="text-2xl lg:text-[3vw] font-semibold text-[#007fff]">
          {practiceQuestion[0].title.split(" ").slice(0, 2).join(" ")}
          <span className="text-[#FF2C45]">
            {practiceQuestion[0].title.split(" ").slice(2, 5).join(" ")}
          </span>
        </h1>
      )}
      <div className="w-full flex flex-wrap items-start justify-between my-4 lg:my-8 p-4 lg:p-10 shadow-xl border-t-2 border-[#030260] rounded-lg">
        {items && items[0] && items[0].items.map((item, index) => (
          <div key={index} className="w-full lg:w-[48%] flex flex-col mt-4 xl:mt-8">
            <span className={`w-full ${index % 2 === 0 ? '' : ''}  items-center justify-between`}>
              {item && (
                <span className="w-full text-gray-700 font-medium">
                  <span className="text-gray-800">
                  <BlockContent blocks={item.questionValue} />
                  </span>
                  <button
                    className="bg-[#007fff] px-2 py-1 hover:scale-105 rounded-md text-sm font-medium text-[#FFFFFF] mt-2"
                    onClick={() => navigate("/practiceDetail", { state: item })}
                  >
                    See Answer
                  </button>
                </span>
              )}
            </span>
            <span className="h-[1px] bg-black w-full my-4"></span>
          </div>
        ))}
      </div>
    </div>
  );
};








// import React, { useContext, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { DataContext } from "../../context/DataContext";
// import BlockContent from "@sanity/block-content-to-react";

// export const HomePracticeSec = () => {
//   const { practiceQuestion, loading } = useContext(DataContext);
//   const navigate = useNavigate();
//   console.log("practiceQuestion", practiceQuestion);

//   if (loading) <></>;

//   return (
//     <div className="practice-question-section flex flex-col items-center justify-center lg:mb-20 px-[4vw] ">
//       {practiceQuestion && (
//         <h1 className="text-2xl lg:text-[3vw] font-semibold text-[#007fff]">
//           {" "}
//           {practiceQuestion[0].title.split(" ").slice(0, 2).join(" ") + " "}
//           <span className="text-[#FF2C45]">
//             {practiceQuestion[0].title.split(" ").slice(2, 5).join(" ") + " "}
//           </span>
//         </h1>
//       )}
//       <div className="w-full flex flex-wrap items-start justify-between my-4 lg:my-8 p-4 lg:p-10 shadow-xl border-t-2 border-[#030260] rounded-lg">
//         {practiceQuestion &&
//           practiceQuestion[0].questionAns[0].items.map((item, index) => (
//             <div
//               key={index}
//               className="w-fit flex flex-col items-start"
//             >
//              <span className={`w-full${index % 2 == 0 ?'mt-4' : 'm-0'} flex items-center justify-between`}>
//               {item && (
//                 <span className="w-full  text-gray-700 font-medium">
//                   <BlockContent blocks={item.questionValue} />
//                   <button
//                     className="bg-[#007fff] px-4 py-1 hover:scale-105 rounded-full text-sm font-medium text-[#FFFFFF] mt-2"
//                     onClick={() => {
//                       navigate("/practiceDetail", { state: item });
//                     }}
//                   >
//                     See Answer{" "}
//                   </button>
//                 </span>
//               )}
//               </span>
//               <span className="h-[1px] bg-black w-full my-4"> </span>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// const toggleAnswer = (subjectIndex, questionIndex) => {
//   if (
//     expandedIndex?.subjectIndex === subjectIndex &&
//     expandedIndex?.questionIndex === questionIndex
//   ) {
//     setExpandedIndex(null);
//   } else {
//     setExpandedIndex({ subjectIndex, questionIndex });
//   }
// };

//   return (
//     <div className="practice w-full flex flex-col items-center justify-center py-[2vw] px-[4vw]">
//       {homeHeroSubjects && <p className="text-[#FF2C45] border border-[#FF2C45] px-4 py-1 rounded-full">
//        {homeHeroSubjects[0].title}
//       </p>}
//       {homeHeroSubjects && <h1 className="text-base lg:text-[2.25vw] lg:leading-[5vw] text-[#2d2d2d] font-semibold text-center mt-4">
//         {homeHeroSubjects[0].subTitle}
//       </h1>}
//       <div className="w-full flex items-start my-4 overflow-x-auto space-x-6 py-5">
//         {homeHeroSubjects && homeHeroSubjects[0].subjects.map((subject, subjectIndex) => (
//           <div
//             key={subjectIndex}
//             className="subject-section min-w-[90vw] lg:min-w-[45vw] bg-[#FFF6F8] flex flex-col rounded-xl shadow-md"
//            >
//             <strong
//               className={`text-center rounded-t-xl py-4 text-slate-50 font-semibold lg:text-[1.5vw]`}
//               style={{ backgroundColor: subject.color.hex }}
//             >
//               {subject.subjectName}
//             </strong>
//             {subject.quesAns.map((question, questionIndex) => (
//               <div
//                 key={questionIndex}
//                 className="border-b flex flex-col items-start m-4 "
//               >
//                 <p className="text-[#3B3F5C] text-xs lg:text-[1.1vw] font-normal opacity-70">
//                   {question.date}
//                 </p>
//                 <div
//                   className="w-full flex items-center justify-between space-x-12 pb-2 cursor-pointer"
//                   onClick={() => toggleAnswer(subjectIndex, questionIndex)}
//                 >
//                   <p className="text-[#3B3F5C] text-sm lg:text-[1.15vw] lg:leading-[2.4vw] font-normal opacity-85 mt-5">
//                     {question.question}
//                   </p>
//                   <img
//                     src={img}
//                     alt="down-arrow"
//                     className={`transition-transform duration-600 ${
//                       expandedIndex?.subjectIndex === subjectIndex &&
//                       expandedIndex?.questionIndex === questionIndex
//                         ? "rotate-180"
//                         : ""
//                     }`}
//                   />
//                 </div>
//                 {expandedIndex?.subjectIndex === subjectIndex &&
//                   expandedIndex?.questionIndex === questionIndex && (
//                     <p className="text-[#3B3F5C] text-sm lg:text-[1.20vw] lg:leading-[2.5vw] font-normal pb-6"><SanityBlockContent blocks={question.answer}></SanityBlockContent></p>
//                   )}
//               </div>
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

export default HomePracticeSec;
