import React, { useContext} from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { HeroHeaderSyllabus } from "../../HeroHeaderSyllabus";
import { HeaderButton } from "../../HeroHeaderSyllabus";
import { HeroHeader } from "../../HeroHeaderSyllabus";
import { RightSideBar } from "../../../rightSideBar/RightSideBar";
import { SectionHeader } from "./Section";

export const TopicDetailContent = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName, title, topic } = useParams();
 

  let header = {};
  if (loading) return <></>;

  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const syllabus = subExam.syllabus;
  console.log("syllabus", subExam.syllabus);

  const questions = subExam.questions;
  const subjectQuestions = questions.items.filter(
    (x) => x._type === "nSubject"
  );

  let Questions = null;
  Questions = questions.items.filter(
    (x) => x.title === title && x.topic == topic
  );
  console.log("qs", questions);

  if (examName && subExamName && exams && syllabus) {
    var headerButtons = [
      new HeaderButton(`Exam notes`, `/notes`),
      new HeaderButton(
        `${examName} ${subExamName} PYQs`,
        `/${examName}/${subExamName}/pyqs`
      ),
    ];
    header = new HeroHeader(
      headerButtons,
      examName + " " + subExamName + " Questions",
      syllabus.author,
    );
  }

  console.log("questions", Questions);

  return (
    <>
      <Header />
      <div className="mt-4">
      {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <main className="scroll-section flex items-start gap-4 py-[4vw] mx-2 sm:mx-4 lg:mx-8">
        <div
          className="question-detail-page w-full lg:w-3/4 p-6 flex flex-col items-start space-y-2 bg-[#FFF]"
          // ref={leftChildRef}
        >
          {title && (
            <h1 className="lg:text-[2.25vw] lg:leading-[3.5vw] text-xl text-[#007fff] font-extrabold">
              {title} - {topic} Questions in {examName} {subExamName}
            </h1>
          )}
          {Questions &&
            Questions.map((x) => {
              return x.items.map((y) => {
                if(y._type == "sectionHeading"){
                    return(
                    <SectionHeader value = {y.value}/>)
                }
                if (y._type == "mcq") {
                  return (
                    <div
                      key={y.id}
                      className="w-full flex flex-col items-start  rounded-sm "
                    >
                      <div className="text-[1.25vw] font-normal text-[#007fff]">
                        <div className="prose">
                          <BlockContent blocks={y.question} />
                        </div>
                      </div>
                      {/* <span className="w-full h-[1px] bg-gray-400 "></span> */}
                      <div className="my-2">
                        {y.options.map((z) => {
                          return (
                            <BlockContent key={z.id} blocks={z.optionValue} />
                          );
                        })}
                      </div>
                      {/* <span className="w-full h-[1px] bg-gray-500 "></span> */}
                      <div className="my-4 flex flex-col items-start space-y-3">
                        <strong>Correct Answer: </strong>
                        {y.options[y.solution.correctAns - 1] && (
                          <BlockContent
                            blocks={
                              y.options[y.solution.correctAns - 1].optionValue
                            }
                          />
                        )}
                      </div>
                      {/* <span className="w-full h-[1px] bg-gray-500 "></span> */}
                      <div>
                        <h1 className="py-2">
                          <strong>Exaplanation: </strong>
                        </h1>
                        <BlockContent blocks={y.solution.explanation} />
                      </div>
                    </div>
                  );
                }
                if (y._type == "genericQuestion")
                  return (
                    <div key={y.id}>
                      <BlockContent blocks={y.questionValue} />
                    </div>
                  );
              });
            })}

          <ul>
            <li></li>
          </ul>
        </div>
        <div
          className={`lg:w-1/4 lg:block hidden -mt-2`}
        >
          <RightSideBar
            examName={examName}
            subExamName={subExamName}
            title={title}
            topic={topic}
          />
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
