import React from 'react'
import { About } from './About'
import ScrollToTopButton from '../../unusableComponent/utils/scrollToTopButton'
import Header from '../header/Header'
import { Footer } from '../footer/Footer'

export const AboutPage = () => {
  return (
    <div >
         <Header />
         <div className='h-[100vh]'>
         <About />
         </div>
         <Footer />
         <ScrollToTopButton />
    </div>
  )
}
