import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../../context/DataContext";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { urlFor, urlForFile } from "../../../../sanityCli";
import { parseAssetId } from "@sanity/asset-utils";
import { HeroHeaderSyllabus, HeroHeader, HeaderButton } from "../../HeroHeaderSyllabus";

export const Ebooks = () => {
  const { exams, ebooks, loading } = useContext(DataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth < 768) {
        setItemsPerPage(3); // Mobile view: 3 items per page
      } else {
        setItemsPerPage(6); // Desktop view: 6 items per page
      }
    };

    window.addEventListener("resize", updateItemsPerPage);
    updateItemsPerPage(); // Initial check

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  if (loading) return <></>;

  const syllabus = exams[0].subExams[0].syllabus;

  var headerButtons = [
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} Questions`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/questions`
    ),
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} PYQs`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/pyqs`
    ),
  ];
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate1 = `${year}-${month}-${day}`;
  const header = new HeroHeader(
    headerButtons,
    `Ebooks`,
    "Admin",
    `${formattedDate1}`
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEbooks = ebooks[0].eBooksList.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(ebooks[0].eBooksList.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Header />
      <div className="mt-[12vw]">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <div className="flex flex-col items-center justify-center space-y-4 mb-20">
        {ebooks && (
          <h1 className="text-2xl lg:text-4xl font-semibold pt-16 pb-8 text-[#007fff]">
            {ebooks[0].ebookPageTitle}
          </h1>
        )}
        <div className="w-full flex flex-wrap items-start justify-between px-8 lg:pb-20">
          {currentEbooks.map((x, i) => (
            <a
              href={urlForFile(parseAssetId(x.ebookFile.asset._ref))}
              key={i}
              target="_blank"
              rel="noopener noreferrer"
              className="notes-box w-full lg:w-[30%] bg-white rounded-lg px-4 py-8 flex flex-col space-y-5 items-center shadow-lg mb-6 transition-all duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-[#007fff] text-[#007fff] hover:text-white"
            >
              <p className="text-xl lg:text-2xl  font-semibold hover:text-white transition-all duration-500 ease-in-out">
                {x.header}
              </p>
              <img
                src={urlFor(x.ebookImage)}
                alt={x.header}
                className="w-20 transition-all duration-500 ease-in-out"
              />
            </a>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls flex space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="text-[#007fff] font-semibold px-4 py-2 rounded-md border border-[#030260] hover:bg-[#007fff] hover:text-white transition-all duration-300"
          >
            Previous
          </button>
          <p className="text-[#007fff] font-semibold px-4 py-2 rounded-md">
            Page {currentPage} of {totalPages}
          </p>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="text-[#007fff] font-semibold px-4 py-2 rounded-md border hover:bg-[#007fff] border-[#030260] hover:text-white transition-all duration-300"
          >
            Next
          </button>
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
