import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";




export const RightSideBar = ({examName, subExamName, title, topic}) => {

  const CommonRoutes = [
    { path: `/${examName}/${subExamName}/syllabus`, name: `${examName} ${subExamName} syllabus` },
    { path: `/${examName}/${subExamName}/questions`, name: `${examName} ${subExamName} questions`, },
    {
      path: `/${examName}/${subExamName}/full/questions`,
      name: `${examName} ${subExamName} full questions`,
    },
    { path: `/${examName}/${subExamName}/pyqs`, name: `${examName} ${subExamName} pyqs` },
    { path: `/notes`, name: `notes` },
    { path: `/ebooks`, name: `ebooks` },
  ];

  const SubjectRoutes = [
    {
      path: `/${examName}/${subExamName}/syllabus/${title}`,
      name:`${examName} ${subExamName} syllabus ${title}`
    },
    {
      path: `/${examName}/${subExamName}/${title}/questions`,
      name: `${examName} ${subExamName} ${title} questions`
    },
    { path: `/${examName}/${subExamName}/${title}/pyqs`, name:`${examName} ${subExamName} ${title} pyqs` },
    { path: `notes/${title}`, name: `Notes-${title}` },
  ];

  const TopicRoutes = [
    {
      path: `/${examName}/${subExamName}/${topic}/topics`,
      name: `${examName} ${subExamName} ${topic} topics`
    },
    {
      path: `/${examName}/${subExamName}/${title}/${topic}/questions`,
      name: `${examName} ${subExamName} ${title} ${topic} questions`,
    },
    { path: `notes/${title}/${topic}`, name: `notes ${title} ${topic}` },
  ];

  let routes = [];
  if(examName && subExamName){
    routes = [...CommonRoutes];

  }
  console.log('routes', examName)

  if(title){
    routes = [...routes, ...SubjectRoutes]
  }

  if(topic){
    routes = [...routes, ...TopicRoutes]
  }

  return (
    <div className="right-sidebar p-4">
      <div className="upsc-notes mb-6">
        <h1 className="text-base lg:text-xl font-semibold mb-6 text-[#007fff]">Important Links</h1>
        <ul className="flex flex-col items-start space-y-4">
          {routes.map((route, index) => (
            <li key={index} className="text-[#000] opacity-90 hover:text-[#007fff] lg:text-lg text-sm font-normal hover:underline cursor-pointer">
              <Link to={`${route.path}`}>
                {route.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
