import React, {useContext} from "react";
import { Link } from "react-router-dom";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { DataContext } from "../../../../context/DataContext";
import { urlFor } from "../../../../sanityCli";

export const Story = () => {
 
  function scrollToId(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const { story, loading} =useContext(DataContext)

  if(loading)
    return<></>
  console.log('story detail',story)

  return (
    <>
    <Header />
      <main className="lg:px-[4vw] lg:py-[12vw] pt-32 pb-8 px-4">
        <div className="flex lg:flex-row flex-col items-center space-y-6 lg:space-y-0 lg:gap-20 ">
          <section className="w-full lg:w-1/2 flex flex-col items-start  space-y-2 lg:space-y-4">
            {story && <h1 className="text-[#007fff] text-2xl lg:text-[3.75vw] lg:leading-[5vw] font-semibold">
              {story[0].heading}
            </h1>}
            <p className="text-[#59595A] opacity-90 lg:text-xl md:text-base text-sm font-normal">
              {story[0].introduction}
            </p>
            <button onClick={() => scrollToId(0)}  className="bg-[#007fff] text-white text-xs lg:text-xl rounded-full text-center px-2 lg:px-8 lg:py-2 py-1">
               {story[0].readStoriesBtn}
            </button>
          </section>
          <section className="w-full lg:w-1/2 bg-[#2D2D2D] rounded-sm">
          <img src={urlFor(story[0].heroImage)} alt='/img' className="w-full "/>
          </section>
        </div>
        <div className="lg:my-32 my-16 flex flex-col items-start space-y-16 lg:space-x-0 ">
          {story[0].achievements.map((achievement, index) => (
            <section id = {index} key={index} className="w-full flex lg:flex-row flex-col items-center justify-between gap-8 lg:gap-20">
              {index % 2 !== 0 ? (
                <>
                  <div className="lg:w-1/2 w-full ">
                    <img src={urlFor(achievement.image)} alt={achievement.title} className="w-full h-full object-cover rounded-sm" />
                  </div>
                  <div className="lg:w-1/2 w-full flex flex-col items-start space-y-4">
                    <h1 className="text-[#007fff] text-base lg:text-2xl font-semibold ">
                      {achievement.title}
                    </h1>
                    <p className="text-[#59595A] lg:text-xl md:text-base text-sm font-normal">
                      {achievement.description}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="lg:w-1/2 w-full flex flex-col items-start space-y-2 lg:space-y-4">
                    <h1 className="text-[#007fff] text-lg lg:text-2xl font-semibold">
                      {achievement.title}
                    </h1>
                  
                    <p className="text-[#59595A] lg:text-xl md:text-base text-sm font-normal">
                      {achievement.description}
                    </p>
                  </div>
                  <div className="lg:w-1/2 w-full bg-[#2d2d2d]">
                    <img src={urlFor(achievement.image)} alt={achievement.title} className="w-full h-full object-cover rounded-sm" />
                  </div>
                </>
              )}
            </section>
          ))}
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

