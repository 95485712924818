import React, { useContext, useState } from "react";
import playButtonIcon from "./playBtn1.png"; // Ensure you have this icon
import { DataContext } from "../../context/DataContext";

export const HomeVideo = () => {
  const { video, loading } = useContext(DataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPageDesktop = 6;
  const videosPerPageMobile = 4;

  if (loading) return <></>;

  const videos = video ? video[0].videoData : [];

  const isMobile = window.innerWidth < 768; // Adjust this based on your design breakpoints
  const videosPerPage = isMobile ? videosPerPageMobile : videosPerPageDesktop;

  const totalPages = Math.ceil(videos.length / videosPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const startIndex = (currentPage - 1) * videosPerPage;
  const endIndex = startIndex + videosPerPage;
  const displayedVideos = videos.slice(startIndex, endIndex);

  return (
    <div className="w-full flex flex-col space-y-2 lg:space-y-5 items-center justify-center p-[4vw]">
      {video && (
        <p className="text-[#FF2C45] border border-[#FF2C45] text-xl px-4 py-1 rounded-full">
          {video[0].heading}
        </p>
      )}
      {video && (
        <h1 className="lg:text-[2.25vw] lg:leading-[4vw] text-2xl text-[#007fff] font-semibold text-center mt-4">
          {video[0].subHeading}
        </h1>
      )}
      <div className="w-full flex flex-wrap items-start justify-between mb-20">
        {displayedVideos.map((data, index) => (
          <a
            key={index}
            href={data.videoLink}
            target="_blank"
            rel="noopener noreferrer"
            className="w-[100%] sm:w-[48%] lg:w-[30%] relative flex-shrink-0 mb-8 flex flex-col items-start space-y-2 shadow-xl rounded-md   transform transition-transform duration-500 ease-in-out hover:scale-95  hover:shadow-2xl" // Hover and transition effect
              style={{ animation: `fadeIn 0.5s ease-in-out ${(index + 1) * 0.1}s` }} // Staggered animationl"
          >
            <div className="relative w-full">
              <img
                src={data.thumbnailLink}
                alt={data.title}
                className="w-full h-48 xl:h-60 rounded-t-md opacity-90 transition-opacity duration-300 hover:opacity-100"
              />
              <img
                src={playButtonIcon}
                alt="Play"
                className="absolute inset-0 m-auto w-10 opacity-90 transition-opacity duration-300 hover:opacity-100 hover:scale-110"
                style={{
                  top: "30%",
                  left: "10%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>
            <p className="lg:text-[1.25vw] lg:leading-[2vw] text-sm md:text-base text-center font-medium text-gray-950 p-2">
              {data.subTitle}
            </p>
          </a>
        ))}
      </div>
      <div className="flex space-x-4 items-center py-4 lg:py-10">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-1 bg-[#007fff] text-white rounded-full disabled:opacity-50"
        >
          Previous
        </button>
        <p className="text-[#007fff] text-xs lg:text-base">
          Page {currentPage} of {totalPages}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-1 bg-[#007fff] text-white rounded-full disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};
