import React, { useState, useEffect, useContext } from "react";
import client, { urlFor } from "../../../../../sanityCli";
import { v4 as uuidv4 } from "uuid";
import { DataContext } from "../../../../../context/DataContext";
import { HomeConsultant } from "./HomeConsultant";
import Header from "../../../../header/Header";
import { Footer } from "../../../../footer/Footer";
import ScrollToTopButton from "../../../../../unusableComponent/utils/scrollToTopButton";
import { About } from "../../../../about/About";

function QuesAns() {
  const [question, setQuestion] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [commentInput, setCommentInput] = useState(null);
  const { questions, loading } = useContext(DataContext);
  if (loading) <></>;

  function convertDateToPrintable(date) {
    let dateArray = new Date(date).toString().split(" ");
    let printableDate =
      dateArray[0] +
      " " +
      dateArray[1] +
      " " +
      dateArray[2] +
      " " +
      dateArray[3] +
      " " +
      dateArray[4].slice(0, 5);

    return printableDate;
  }

  // Function to pick a random image from the x object
  const getRandomImage = () => {
    // Assuming x is an object with an array of images
    const x = question ? question.image : [];
    // console.log("image", x);
    const randomIndex = Math.floor(Math.random() * x.length);
    return x[randomIndex].image;
  };

  return (
    <>
      <Header />
      <div className="my-28 lg:my-40">
        <div className="my-32">
          <About /> 
        </div>

        <HomeConsultant />
        {questions &&
          questions[0].commentText.map((comment, index) => (
            <div
              key={index}
              className="mb-0 border-b border-[#030260] pb-4 md:pb-8 flex flex-col items-stretch md:items-start m-4 lg:m-12"
            >
              <div className="w-full flex items-center lg:flex-row  my-2  gap-2 lg:gap-4">
                <img
                  src={urlFor(questions[0].userImage)}
                  alt="User"
                  className="w-10 h-10 md:w-8 md:h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 2xl:w-16 2xl:h-16 rounded-full"
                />
                <div className="flex flex-col items-start  space-y-[2px] lg:space-y-0 lg:flex-row lg:items-center gap-[4%] md:w-[30vw]">
                  <p className="text-base md:text-lg lg:text-lg xl:text-2xl  text-[#000000] font-semibold">
                    {comment.name}
                  </p>
                  <p className="text-gray-600 text-xs lg:text-sm ">
                    {convertDateToPrintable(comment.time)}
                  </p>
                </div>
              </div>
              <p className="text-[#] mb-4 font-normal text-start md:text-start text-[10px] md:text-[10px] lg:text-sm xl:text-xl  lg:text-[1.65vw] lg:leading-[3.3vw] mt-2">
                <strong>Question:</strong> {comment.question}
              </p>
              <p className=" font-normal text-start md:text-start text-[10px] md:text-[10px] lg:text-sm xl:text-xl  lg:text-[1.65vw] lg:leading-[3vw] lg:mt-2">
                <strong>Answer:</strong> {comment.answer}
              </p>
            </div>
          ))}
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default QuesAns;
