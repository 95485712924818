import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import Header from "../../../header/Header";

export const PyqDetailPage = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName, title } = useParams();

  if (loading) return <></>;

  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const questions = subExam.pyqs;
  const subjectQuestions = questions.items.filter(
    (x) => x._type === "nSubject"
  );
  let Questions = null;
  if (title) Questions = questions.items.filter((x) => x.title === title);
  else Questions = questions.items.filter((x) => x._type === "full");

  console.log("pyqs questions", Questions);
  return (
    <>
    <Header />
    <div className="question-detail-page">
      {title && (
        <h1>

          {title} Questions in {examName} {subExamName}
        </h1>
      )}
      {!title && (
        <h1>
          Full Questions in {examName} {subExamName}
        </h1>
      )}
      {Questions.map((x) => {
        return x.items.map((y) => {
          return (
            <div key={y.id}>
              <BlockContent blocks={y.question} />
              {y.options.map((z) => {
                return <BlockContent key={z.id} blocks={z.optionValue} />;
              })}
            </div>
          );
        });
      })}
    </div>
    <Footer />
    <ScrollToTopButton />
    </>
  );
};

