import React, { useContext, useState } from "react";
import {
  createGradient3Colors,
  createGradient4Colors,
  createGradient5Colors,
} from "../bgcolor/BgColor";
import { Link } from "react-router-dom";
import downArrow from "../../assets/Home/down-arrow.svg";
import { DataContext } from "../../context/DataContext";
import image from "./menu.png"; // Hamburger menu icon
import image2 from "./cross.jpeg"; // Cross icon for closing menu
import Marquee from "react-fast-marquee";
import img from "./Logo2.png";

const Header = () => {
  const { headerFooter, exams, notes, bgColor, loading } =
    useContext(DataContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [nestedDropdown, setNestedDropdown] = useState(null);
  const [navbarColor, setnavbarColor] = useState(null);

  if (loading) return <></>;

  if (!navbarColor) {
    const colorBg = bgColor;
    if (colorBg[0].backgroundType === "threeColors") {
      setnavbarColor(
        createGradient3Colors(
          colorBg[0].colors.threeColors.color1.hex,
          colorBg[0].colors.threeColors.color2.hex,
          colorBg[0].colors.threeColors.color3.hex
        )
      );
    } else if (colorBg[0].backgroundType === "fourColors") {
      setnavbarColor(
        createGradient4Colors(
          colorBg[0].colors.fourColors.color1.hex,
          colorBg[0].colors.fourColors.color2.hex,
          colorBg[0].colors.fourColors.color3.hex,
          colorBg[0].colors.fourColors.color4.hex
        )
      );
    } else if (colorBg[0].backgroundType === "fiveColors") {
      setnavbarColor(
        createGradient5Colors(
          colorBg[0].colors.fiveColors.color1.hex,
          colorBg[0].colors.fiveColors.color2.hex,
          colorBg[0].colors.fiveColors.color3.hex,
          colorBg[0].colors.fiveColors.color4.hex,
          colorBg[0].colors.fiveColors.color5.hex
        )
      );
    } else if (colorBg[0].backgroundType === "oneColor") {
      setnavbarColor(colorBg[0].colors.oneColor.color.hex);
    }
  }

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setDropdown(null);
    setNestedDropdown(null);
  };

  const toggleDropdown = (index) => {
    setDropdown((prevDropdown) => (prevDropdown === index ? null : index));
    setNestedDropdown(null);
  };

  const toggleNestedDropdown = (index) => {
    setNestedDropdown((prevNestedDropdown) =>
      prevNestedDropdown === index ? null : index
    );
  };

  return (
    <div className="w-full fixed top-0 z-50">
     {headerFooter && <Marquee className="w-full z-50 bg-[#007fff] py-1 lg:text-lg font-medium tracking-wider text-white">
        {headerFooter[0].headerMovingText}
      </Marquee>}
      <header className={`w-full lg:flex`} style={{ background: navbarColor }}>
        <div className="lg:w-[35vw] flex items-center justify-between  md:px-8 sm:px-[5vw] px-4 py-2 lg:py-3 lg:px-[4vw]">
          <Link to="/" className="logo">
            <img src={img} alt="Logo" className="w-32 lg:w-48" />
          </Link>
          <button
            onClick={toggleMenu}
            className="lg:hidden w-5 p-1 flex items-center justify-center bg-[#fff] text-white rounded"
          >
            <img
              src={isMenuOpen ? image2 : image}
              alt="Menu Icon"
              className="w-3"
            />
          </button>
        </div>
        <nav
          className={`lg:w-[65vw] lg:flex items-center pt-4 justify-between ${
            isMenuOpen ? "block" : "hidden"
          } w-full`}
        >
          <ul className="w-full flex flex-col lg:flex-row items-start lg:items-center lg:justify-between space-y-4 lg:space-y-0  px-6 py-0 lg:pr-[4vw] md:h-auto">
            <li className="border-b-2 pb-4 w-full lg:border-none lg:w-auto">
              <Link
                to="/"
                className="cursor-pointer text-base lg:text-[1.25vw] font-semibold text-[#007fff]"
              >
                होम
              </Link>
            </li>
            {exams &&
              exams.map((x, i) => (
                <li
                  key={i}
                  className="relative border-b-2 pb-4 w-full lg:border-none lg:w-auto"
                  onMouseEnter={() => toggleDropdown(i)}
                  onMouseLeave={() => toggleDropdown(null)}
                >
                  <span className="cursor-pointer text-base lg:text-[1.25vw] font-semibold text-[#007fff] flex items-center">
                    {x.examName}
                    <img
                      src={downArrow}
                      alt="down-arrow"
                      className="w-3 ml-1"
                    />
                  </span>
                  <ul
                    className={`absolute z-50 min-w-[10vw] left-[15vw] lg:left-[0vw] top-0 lg:top-6 bg-[#007fff] text-[#FFFFFF] text-base lg:text-[1.25vw] font-semibold  rounded-md shadow-lg ${
                      dropdown === i ? "block" : "hidden"
                    }`}
                  >
                    {x.subExams.map((y, j) => (
                      <li
                        key={j}
                        className="w-full px-4 py-2 flex items-center justify-between hover:bg-[#EFF6FF]  text-base lg:text-[1.25vw] font-semibold hover:text-[#007fff] border-b"
                        onMouseEnter={() => toggleNestedDropdown(j)}
                        onMouseLeave={() => toggleNestedDropdown(null)}
                      >
                        <span>{y.title}</span>
                        <img
                          src={downArrow}
                          alt="down-arrow"
                          className="w-3 ml-2"
                        />
                        <ul
                          className={`absolute min-w-[10vw] left-[28vw] lg:left-[10vw] top-0 bg-[#007fff] text-[#ffffff] border border-gray-300 rounded-md shadow-lg ${
                            nestedDropdown === j ? "block" : "hidden"
                          }`}
                        >
                          <li className="w-full px-4 py-2 hover:bg-[#EFF6FF]  text-base lg:text-[1.25vw] font-semibold hover:text-[#007fff] border-b">
                            <Link to={`/${x.examName}/${y.title}/syllabus`}>
                              Syllabus
                            </Link>
                          </li>
                          <li className="w-full px-4 py-2 hover:bg-[#EFF6FF] text-base lg:text-[1.25vw] font-semibold text-[#ffffff] hover:text-[#007fff] border-b-2">
                            <Link to={`/${x.examName}/${y.title}/questions`}>
                              Questions
                            </Link>
                          </li>
                          <li className="w-full px-4 py-2 hover:bg-[#EFF6FF] text-base lg:text-[1.25vw] font-semibold text-[#ffffff] hover:text-[#007fff] border-b-2">
                            <Link to={`/${x.examName}/${y.title}/pyqs`}>
                              PYQs
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}

            <li
              className="relative border-b-2 pb-4 w-full lg:border-none lg:w-auto"
              onMouseEnter={() => toggleDropdown(77)}
              onMouseLeave={() => toggleDropdown(null)}
            >
              <span className="cursor-pointer text-base lg:text-[1.25vw] font-semibold text-[#007fff] flex items-center">
                स्टडी मेटेरियल्स
                <img src={downArrow} alt="/" className="w-3 ml-1" />
              </span>
              <ul
                className={`absolute z-50 left-[30vw] -top-20 lg:left-0 lg:top-6 lg:right-1 bg-[#007fff] border border-gray-300 rounded-md shadow-lg ${
                  dropdown === 77 ? "block" : "hidden"
                }`}
              >
                {notes &&
                  notes[0].subjects.map((x) => {
                    return (
                      <li className="px-4 py-2 hover:bg-[#EFF6FF] font-semibold text-[#fff] hover:text-[#007fff]  border-b-2">
                        <Link to={`/notes/${x.subjectName}`} className="">
                          {x.subjectName}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </li>

            <li className="border-b-2 pb-4 w-full lg:border-none lg:w-auto">
              <Link
                to="/story"
                className="cursor-pointer text-base lg:text-[1.25vw] font-semibold text-[#007fff]"
              >
                स्टोरी
              </Link>
            </li>
            <li className="border-b-2 pb-4 w-full lg:border-none lg:w-auto">
              <Link
                to="/blog"
                className="cursor-pointer text-base lg:text-[1.25vw] font-semibold text-[#007fff]"
              >
                ब्लॉग
              </Link>
            </li>
            <li className="border-b-2 pb-4 w-full lg:border-none lg:w-auto">
              <Link
                to="/ebooks"
                className="cursor-pointer text-base lg:text-[1.25vw] font-semibold text-[#007fff]"
              >
                ई-बुक्स
              </Link>
            </li>
            <li className="border-b-2 pb-4 w-full lg:border-none lg:w-auto">
              <Link
                to="/contact"
                className="w-full bg-[#007fff] text-slate-50 px-4 py-1 cursor-pointer text-base lg:text-[1.25vw] font-semibold rounded-md"
              >
                संपर्क करें
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;

// import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
// import logo from "../../assets/Home/logo.svg"; // Ensure you have a logo image in your project
// import downArrow from "../../assets/Home/down-arrow.svg";
// import { DataContext } from "../../context/DataContext";
// import Marquee from "react-fast-marquee";
// import img from "./webLogo.svg";

// const Header = () => {
//   const { exams, notes, loading } = useContext(DataContext);
//   console.log("context-data", exams, notes);
//   console.log("notes subject", notes);
//   const [isMenuOpen, setMenuOpen] = useState(false);
//   const [dropdown, setDropdown] = useState(null);
//   const [nestedDropdown, setNestedDropdown] = useState(null);
//   if (loading) return <></>;
//   else console.log("notes", notes);

//   const toggleMenu = () => {
//     setMenuOpen(!isMenuOpen);
//   };

//   const toggleDropdown = (index) => {
//     if (dropdown === index) {
//       setDropdown(null);
//       setNestedDropdown(null);
//     } else {
//       setDropdown(index);
//       setNestedDropdown(null);
//     }
//   };

//   const toggleNestedDropdown = (index) => {
//     if (nestedDropdown === index) {
//       setNestedDropdown(null);
//     } else {
//       setNestedDropdown(index);
//     }
//   };

//   return (
//     <div className="w-full fixed top-0 z-50 mb-8 ">
//      <Marquee className="w-full z-50 bg-[#FF2C45] py-2 text-[1.25vw] font-medium text-white">
//           सफलता की तैयारी आज और अभी से शुरू करें, उच्च गुणवत्ता वाले शोध-समर्थित
//           सामग्री के साथ।
//         </Marquee>
//       <header className="p-[2vw] px-[4vw] bg-[#F6F1EB]">
//         <div className="flex items-center justify-between">
//           <Link to="/" className="logo">
//             <img src={img} alt="Logo" className="w-[16vw]" />
//           </Link>
//           <nav
//             className={`flex items-center justify-between ${isMenuOpen ? "flex-col" : ""}`}
//           >
//             <ul className="flex items-center space-x-8">
//               <li>
//                 <Link
//                   to="/"
//                   className="cursor-pointer text-base lg:text-[1vw] font-semibold text-[#4D5761]"
//                 >
//                   होम
//                 </Link>
//               </li>
//               {exams &&
//                 exams.map((x, i) => (
//                   <li
//                     key={i}
//                     className="flex  items-center gap-1 relative"
//                     onMouseEnter={() => toggleDropdown(i)}
//                     onMouseLeave={() => toggleDropdown(null)}
//                   >
//                     <span className="cursor-pointer text-base lg:text-[1vw] font-semibold text-gray-500">
//                       {x.examName}
//                     </span>
//                     <img src={downArrow} alt="down-arrow" className="w-3" />
//                     <ul
//                       className={`absolute z-50 min-w-[10vw] left-[0vw] top-6 font-semibold bg-white border border-gray-300 rounded-md shadow-lg ${
//                         dropdown === i ? "block" : "hidden"
//                       }`}
//                     >
//                       {x.subExams.map((y, j) => (
//                         <li
//                           key={j}
//                           className="w-full px-4 py-2 flex items-center justify-between hover:bg-[#EFF6FF] text-[#4D5761] border-b-2"
//                           onMouseEnter={() => toggleNestedDropdown(j + 1)}
//                           onMouseLeave={() => toggleNestedDropdown(null)}
//                         >
//                           <span className="w-fit">{y.title}</span>
//                           <span className="w-fit">
//                             <img
//                               src={downArrow}
//                               alt="down-arrow"
//                               className="w-3"
//                             />
//                           </span>
//                           <ul
//                             className={`absolute min-w-[10vw] left-[9.8vw] top-0 font-medium bg-white border border-gray-300 rounded-md shadow-lg ${
//                               nestedDropdown === j + 1
//                                 ? "block shadow-lg"
//                                 : "hidden"
//                             }`}
//                           >
//                             <li className="w-full px-4 py-2 hover:bg-[#EFF6FF] text-[#4D5761] border-b-2">
//                               <Link to={`/${x.examName}/${y.title}/syllabus`}>
//                                 Syllabus
//                               </Link>
//                             </li>
//                             <li className="w-full px-4 py-2 hover:bg-[#EFF6FF] text-[#4D5761] border-b-2">
//                               <Link to={`/${x.examName}/${y.title}/questions`}>
//                                 Questions
//                               </Link>
//                             </li>
//                             <li className="w-full px-4 py-2 hover:bg-[#EFF6FF] text-[#4D5761] border-b-2">
//                               <Link to={`/${x.examName}/${y.title}/pyqs`}>
//                                 PYQs
//                               </Link>
//                             </li>
//                           </ul>
//                         </li>
//                       ))}
//                     </ul>
//                   </li>
//                 ))}

//               <li
//                 className="flex items-center gap-1 relative"
//                 onMouseEnter={() => toggleDropdown(77)}
//                 onMouseLeave={() => toggleDropdown(null)}
//               >
//                 <Link className="cursor-pointer text-base lg:text-[1vw] font-semibold text-[#4D5761]">
//                   स्टडी मेटेरियल्स
//                 </Link>
//                 <span>
//                   <img src={downArrow} alt="/" className="w-3"/>
//                 </span>
//                 <ul
//                   className={`absolute z-50 min-w-[7vw] min-h-12 right-1 top-5  font-semibold bg-white border border-gray-300 rounded-md shadow-lg ${
//                     dropdown === 77 ? "block" : "hidden"
//                   }`}
//                 >
// {notes &&
//   notes[0].subjects.map((x) => {
//     return (
//       <li className="min-w-32 px-4 py-2 hover:bg-[#EFF6FF] text-[#4D5761] border-b-2">
//         <Link
//           to={`/notes/${x.subjectName}`}
//           className="text-gray-500"
//         >
//           {x.subjectName}
//         </Link>
//       </li>
//     );
//   })}
//                 </ul>
//               </li>
//     <li>
//       <Link
//         to="/story"
//         className="cursor-pointer text-base lg:text-[1vw] font-semibold text-[#4D5761]"
//       >
//         माई स्टोरी
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="/blog"
//         className="cursor-pointer text-base lg:text-[1vw] font-semibold text-[#4D5761]"
//       >
//         ब्लॉग
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="/ebooks"
//         className="cursor-pointer text-base lg:text-[1vw] font-semibold text-[#4D5761]"
//       >
//         ई-बुक्स
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="/contact"
//         className="bg-[#01009A] px-2 rounded-sm text-slate-50 text-base lg:text-[1vw] font-semibold py-1"
//       >
//         संपर्क करें
//       </Link>
//     </li>
//   </ul>
// </nav>

// <button
//   onClick={toggleMenu}
//   className="lg:hidden p-2 bg-blue-500 text-white rounded"
// >
//   Menu
// </button>
//         </div>
//       </header>
//     </div>
//   );
// };

// export default Header;
