
import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { Link } from "react-router-dom";
import { urlFor } from "../../sanityCli";
import { AjayAnuragWritten } from "./AjayAnuragWritten";

const HomeHero = () => {
  const { homeHero, loading } = useContext(DataContext);
  const [displayedText, setDisplayedText] = useState("");
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBuildingUp, setIsBuildingUp] = useState(true);

  useEffect(() => {
    if (loading || !homeHero[0]?.animatedTexts) return;

    const animatedTexts = homeHero[0].animatedTexts;

    const intervalId = setInterval(() => {
      const currentText = animatedTexts[currentTextIndex];
      if (!currentText) return;

      if (isBuildingUp) {
        setDisplayedText(currentText.substring(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);

        if (currentIndex + 1 >= currentText.length) {
          setIsBuildingUp(false);
          setCurrentIndex(currentText.length - 1);
        }
      } else {
        setDisplayedText(currentText.substring(0, currentIndex));
        setCurrentIndex(currentIndex - 1);

        if (currentIndex <= 0) {
          setIsBuildingUp(true);
          setCurrentTextIndex((currentTextIndex + 1) % animatedTexts.length);
          setCurrentIndex(0);
        }
      }
    }, 200);

    return () => clearInterval(intervalId);
  }, [homeHero, currentTextIndex, currentIndex, isBuildingUp, loading]);

  if (loading || !homeHero[0]?.animatedTexts) return <></>;

  const img = homeHero[0].bgImg;

  return (
    <div className="relative mt-20 lg:mt-28">
      <img src={urlFor(img)} className="bgImg lg:h-[660px] 2xl:h-[880px] xs:h-96 h-80 curved-img" />
      <div className="absolute lg:top-20 top-12 flex flex-col items-start space-y-3 lg:space-y-2 px-5 lg:px-[3vw] lg:pr-[30vw]">
        <h1 className="text-[#ECF5FF] md:text-start text-center text-2xl lg:text-[4.65vw] font-bold lg:leading-[6vw] lg:pr-0">
          {homeHero[0].header}{" "}
          <span className="text-[#F7D576]">{displayedText}</span>
          <span className="text-[#F7D576] text-2xl lg:text-[3.5vw]">|</span>
        </h1>
        <p className="text-[#fff] text-xs md:text-lg lg:text-[1.5vw] lg:leading-[2.5vw] font-medium lg:pr-0 text-center md:text-start">
          {homeHero[0].fullText}
        </p>
        <Link
          to="/ajay/anurag"
          className="text-[#007fff] absolute -bottom-12 lg:-bottom-40 2xl:bottom-[-40vh] right-[40vw] lg:right-8 text-xs lg:text-xl font-semibold bg-[#FFF] px-2 lg:px-10 py-1 lg:py-2 rounded-md flex items-center justify-center"
        >
          Start learning
        </Link>
      </div>
      <div className="">
        <AjayAnuragWritten />
      </div>
    </div>
  );
};

export default HomeHero;