import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import Header from "../../../header/Header";
import { Footer } from "../../../footer/Footer";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import {
  HeroHeaderSyllabus,
  HeaderButton,
  HeroHeader,
} from "../../HeroHeaderSyllabus";
import { urlFor } from "../../../../sanityCli";

export const QuestionPage = () => {
  const { exams, loading } = useContext(DataContext);
  const { examName, subExamName } = useParams();
  const navigate = useNavigate();
  if (loading) {
    return <div>Loading...</div>;
  }
  console.log("exam value", exams);
  const exam = exams.find((element) => element.examName === examName);
  const subExam = exam.subExams.find(
    (element) => (element.title = subExamName)
  );
  const syllabus = subExam.syllabus;
  const questions = subExam.questions;
  const subjectQuestions = questions.items.filter(
    (x) => x._type === "nSubject"
  );
  const fullQuestions = questions.items.filter((x) => x._type === "full");
  const subjectTitles = [...new Set([...subjectQuestions.map((q) => q.title)])];
  const fullTitles = [
    ...new Set([...fullQuestions.map((question) => question.title)]),
  ];
  console.log("full titles", fullTitles);

  console.log("subject questions", questions);
  console.log("subject title", subjectTitles);

  var headerButtons = [
    new HeaderButton(`${examName} ${subExamName} notes`, `/notes`),
    new HeaderButton(
      `${examName} ${subExamName} PYQs`,
      `/${examName}/${subExamName}/pyqs`
    ),
  ];
  var header = new HeroHeader(
    headerButtons,
    examName + " " + subExamName + " Questions",
    syllabus.author,
    syllabus.date
  );

  return (
    <>
      <Header />
      <div className="mt-28 lg:mt-40">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <div>
        <section className="w-full px-[4vw] flex flex-col items-center space-y-2 py-8 lg:py-20">
          <h1 className="text-[#007fff] lg:text-[2.25vw] lg:leading-[4vw] text-base sm:text-lg md:text-xl  font-semibold">
            {examName} {subExamName} subject wise questions
          </h1>
          <div className="w-full flex flex-wrap items-start justify-between lg:mt-10">
            {subjectTitles.map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() =>
                    navigate(`/${examName}/${subExamName}/${x}/topics`, {
                      state: { questions, x },
                    })
                  }
                  className="w-full sm:w-[45%] lg:w-[30%] bg-white rounded-lg shadow-lg px-2 py-1 flex items-center gap-2 mb-6 transition-all duration-500 ease-in-out  hover:shadow-2xl hover:transform hover:-translate-y-2"
                >
                  {subjectQuestions && (
                    <span className="flex items-center justify-center">
                      <img
                        src={urlFor(
                          subjectQuestions.find((z) => z.title == x).icon
                        )}
                        alt="/icon"
                        className="w-24 lg:w-[6vw]"
                      />
                    </span>
                  )}
                  <span className="flex flex-col items-start lg:space-y-3">
                    <strong className="lg:text-[1.25vw] lg:leading-[1.65vw] text-base">
                      {x}
                    </strong>
                    <span className="lg:text-[1.25vw] text-xs">
                      {subExamName}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <div>
        <section className="w-full px-[4vw]  flex flex-col items-center space-y-2 pb-20 lg:py-10">
        <h1 className="text-[#007fff] lg:text-[2.25vw] lg:leading-[4vw] text-base sm:text-lg md:text-xl  font-semibold">
            {examName} {subExamName} Full question Papers
          </h1>
          <div className="w-full flex flex-wrap items-start justify-between">
            {fullTitles.map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() =>
                    navigate(
                      `/${examName}/${subExamName}/full/${x}/questions`,
                      {
                        state: { questions, x },
                      }
                    )
                  }
                  className="w-full sm:w-[45%] lg:w-[30%] bg-white rounded-lg shadow-lg p-4 flex items-center gap-4 mt-5 transition-all duration-500 ease-in-out  hover:shadow-2xl hover:transform hover:-translate-y-1"
                >
                  <span className="w-12 h-12 rounded-full border-2 border-orange-400 flex items-center justify-center">
                   {fullQuestions && <img
                      src={urlFor(fullQuestions.find((z) => z.title == x).icon)}
                      alt="/icon"
                       className="w-24 lg:w-[6vw]"
                    />}
                  </span>
                  <span className="flex flex-col items-start space-y-2">
                    <strong className="lg:text-[1.25vw] lg:leading-[2.5vw] text-base">
                      {x}
                    </strong>
                    <span className=" lg:text-[1vw] text-xs">{examName}</span>
                  </span>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
