
// import Prelims from "./components/body/upscSection/prelims/Prelims.js";
// import { Mains } from "./components/body/upscSection/mains/Mains.js";
// import { Optional } from "./components/body/upscSection/optional/Optional.js";
// import OptionalDetail from "./components/body/upscSection/optional/OptionalDetail.js";
// import { PreviousYrQuestion } from "./components/body/upscSection/PreviousYrQuestion.js";
// import { Subject } from "./unusableComponent/studyMaterial/Subject.js";

// import UpscQuestionDetail from "./unusableComponent/questionPaper/UpscQuestionDetail.js";
// import UppscQuestionDetail from "./unusableComponent/questionPaper/UppscQuestionDetail.js";
// import { SubjectDetail } from "./unusableComponent/studyMaterial/SubjectDetail.js";
// import { SubjectWise } from "./components/body/upscpreviousYearQuestions/SubjectWise.js";

// import { SubjectWiseDetailPage } from "./components/body/upscpreviousYearQuestions/SubjectWiseDetailPage.js";
// import { UppscSyllabus } from "./components/body/upscpreviousYearQuestions/UppscSyllabus.js";
// import {
//   OptionalSubjectDetail,
//   SubjectBoxList,
// } from "./components/reusableComponent/upscSection/SubjectBoxList.js";
// import { SubjectBoxDetail } from "./components/reusableComponent/upscSection/SubjectBoxDetail.js";
// import { Contact } from "./components/body/contactUs/Contact.js";
// import { HomeConsultant } from "./components/reusablepages/pages/contactPage/HomeConsultant.js";
import { createGradient5Colors, createGradient4Colors, createGradient3Colors } from "./components/bgcolor/BgColor.js";
import React, { useState, useEffect, useContext } from "react";
import client, { urlFor } from "./sanityCli.js";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { Home } from "./components/pageComponent/Home.js";
import { About } from "./components/about/About.js";
import { Blog } from "./components/blog/Blog.js";
import BlogDetail from "./components/blog/BlogDetail.js";
import { PrivacyPolicy } from "./components/privacyPolicy/PrivacyPolicy.js";
import { TermServices } from "./components/termsServices/TermServices.js";
import { DataContext, DataProvider } from "./context/DataContext.js";
import { SyllabusPage } from "./components/reusablepages/pages/syllabus/SyllabusPage.js";
import { DetailSyllabus } from "./components/reusablepages/pages/syllabus/DetailSyllabus.js";
import { QuestionPage } from "./components/reusablepages/pages/questionPage/QuestionPage.js";
import { QuestionPageDetail } from "./components/reusablepages/pages/questionPage/QuestionPageDetail.js";
import { PyqDetailPage } from "./components/reusablepages/pages/pyqs/PyqDetailPage.js";
import { Pyq } from "./components/reusablepages/pages/pyqs/Pyq.js";
import { SubjectNotes } from "./components/reusablepages/pages/notes/SubjectNotes.js";
import { Story } from "./components/reusablepages/pages/strory/Story.js";
import { NotesDetailPage } from "./components/reusablepages/pages/notes/NotesDetailPage.js";
import { NotesMainPage } from "./components/reusablepages/pages/notes/NotesMainPage.js";
import { Ebooks } from "./components/reusablepages/pages/eBook/Ebooks.js";
import QuesAns from "./components/reusablepages/pages/contactPage/jsfile/QuesAns.js";
import { TopicDetail } from "./components/reusablepages/pages/questionPage/TopicDetail.js";
import { TopicDetailContent } from "./components/reusablepages/pages/questionPage/TopicDetailContent.js";
import { AjayAnnurag } from "./components/ajayAnurag/AjayAnnurag.js";
import { QuestionFooter } from "./components/footer/QuestionFooter.js";
import { PracticeDetail } from "./components/reusablepages/pages/praciceQuestionetailPage/PracticeDetail.js";
import { AboutPage } from "./components/about/AboutPage.js";

const defaultRoutes = [
  { path: "/", element: <Home /> },
  { path: "/blog", element: <Blog /> },
  { path: "/blog/tag/:path", element: <Blog /> },
  { path: "/about", element: <About /> },
  { path: "/about/us", element: <AboutPage /> },
  { path: "/contact", element: <QuesAns /> },
  { path: "/privacyPolicy", element: <PrivacyPolicy /> },
  { path: "/termsServices", element: <TermServices /> },
  { path: ":examName/:subExamName/syllabus", element: <SyllabusPage /> },
  {
    path: ":examName/:subExamName/syllabus/:title",
    element: <DetailSyllabus />,
  },
  { path: ":examName/:subExamName/questions", element: <QuestionPage /> },
  {
    path: ":examName/:subExamName/:title/questions",
    element: <QuestionPageDetail />,
  },
  {
    path: ":examName/:subExamName/:subject/topics",
    element: <TopicDetail />,
  },
  {
    path: ":examName/:subExamName/full/:title/questions",
    element: <QuestionPageDetail />,
  },{
    path: ":examName/:subExamName/:title/:topic/questions",
    element: <TopicDetailContent />,
  },
  { path: ":examName/:subExamName/pyqs", element: <Pyq /> },
  { path: ":examName/:subExamName/:title/pyqs", element: <PyqDetailPage /> },
  { path: ":examName/:subExamName/full/pyqs", element: <PyqDetailPage /> },
  { path: "notes/:subjectName", element: <SubjectNotes /> },
  { path: "/notes", element: <NotesMainPage /> },
  { path: "/ebooks", element: <Ebooks /> },
  { path: "/blog/:path", element: <BlogDetail /> },
  { path: "/story", element: <Story /> },
  {path: '/ajay/anurag', element: <AjayAnnurag />},
  {path: '/questionpaper', element: <QuestionFooter  />},
  { path: "notes/:subjectName/:topic", element: <NotesDetailPage /> },
  { path: "/practiceDetail", element: <PracticeDetail /> },
];

const Initialize = () => {

  const [routes, setRoutes] = useState(defaultRoutes);
  const [bgColor, setBgColor] = useState(null) 
  const navigate = useNavigate();
  const{notes, exams, ebooks, blogs, story, homeHero, homeHeroSubjects, questions, adminInfo,privacyPolicy, termsPolicy, video, loading} =useContext(DataContext)
 if (loading) <></>
  useEffect(() => {
    const fetchBgColor = async () => {
      const colorBg = await client.fetch(`*[_type == "bgColorSchema"]`);
      setBgColor(colorBg[0]);
      console.log("color", colorBg[0].colors.oneColor);
      if(colorBg[0].backgroundType == 'threeColors'){
        document.body.style.background = createGradient3Colors(colorBg[0].colors.threeColors.color1.hex,colorBg[0].colors.threeColors.color2.hex,colorBg[0].colors.threeColors.color3.hex)
      }
      if(colorBg[0].backgroundType == 'fourColors'){
        document.body.style.background = createGradient4Colors(colorBg[0].colors.fourColors.color1.hex,colorBg[0].colors.fourColors.color2.hex,colorBg[0].colors.fourColors.color3.hex, colorBg[0].colors.fourColors.color4.hex)
      }
      if(colorBg[0].backgroundType == 'fiveColors'){
        document.body.style.background = createGradient5Colors(colorBg[0].colors.fiveColors.color1.hex,colorBg[0].colors.fiveColors.color2.hex,colorBg[0].colors.fiveColors.color3.hex,colorBg[0].colors.fiveColors.color4.hex, colorBg[0].colors.fiveColors.color5.hex)
      }
      if(colorBg[0].backgroundType == 'oneColor'){
        document.body.style.background = colorBg[0].colors.oneColor.color.hex;
      }
    }
    const fetchPageContent = async () => {
      try {
        const data = await client.fetch('*[_type =="pageContent"][0]');
        return data;
        // console.log()
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    };
    fetchBgColor().then(() =>{
      fetchPageContent()
      .then((data) => {
        if (data) {
          document.title = data.title;
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", data.description);
          if (data.favicon) {
            let link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/png";
            link.rel = "shortcut icon";
            link.href = urlFor(data.favicon);
            document.getElementsByTagName("head")[0].appendChild(link);
          }

          if (data.header && data.header.length > 0) {
            data.header.forEach((headerHtml) => {
              const div = document.createElement("div");
              div.innerHTML = headerHtml;
              while (div.children.length > 0) {
                document.head.appendChild(div.children[0]);
              }
            });
          }
        }
      })
      .catch((error) =>
        console.error("ErrorDocument 404 /notfound.html", error)
      );
    })
      window.scrollTo(0, 0);

  }, [navigate, defaultRoutes]);

  const element = useRoutes(routes);
  return element;
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <DataProvider>
        <Initialize />
      </DataProvider>
    </Router>
  </React.StrictMode>
);
