// src/context/DataContext.js
import React, { createContext, useState, useEffect } from 'react';
import client from '../sanityCli';


const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [headerFooter, setHeaderFooter] = useState(null);
  const [notes, setNotes] = useState(null);
  const [exams, setExams] = useState(null);
  const [ebooks, setEbooks]= useState(null)
  const [blogs, setBlogs] = useState(null)
  const [story, setStory] = useState(null)
  const [homeHero, setHomeHero] = useState(null)
  const [homeHeroSubjects, setHomeHeroSubjects] = useState(null)
  const [questions, setQuestions] = useState(null)
  const [adminInfo, setAdminInfo] = useState(null)
  const [video, setVideo] = useState(null)
  const [privacyPolicy, setPrivacyPolicy] = useState(null)
  const [termsPolicy, setTermsPolicy] = useState(null) 
  const [bgColor, setBgColor] = useState(null) 
  const [practiceQuestion, setPracticeQuestion] = useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const colorBg = await client.fetch(`*[_type == "bgColorSchema"]`);
        setBgColor(colorBg);

        const headerData = await client.fetch(`*[_type == "headerFooter"]`);
        console.log('headerData', headerData)
        setHeaderFooter(headerData);

        const noteResult = await client.fetch(`*[_type == "newNotes"]`);
        setNotes(noteResult);

        const examResult = await client.fetch(`*[_type == "exam"]`);
        setExams(examResult);

        const ebookData = await client.fetch(`*[_type == "ebooks"]`);
        setEbooks(ebookData);

        const blogData = await client.fetch(`*[_type == "blogPage"]`);
        setBlogs(blogData);

        const storyData = await client.fetch(`*[_type == "successStory"]`);
        setStory(storyData);

        const homeHeroData = await client.fetch(`*[_type == "homeHero"]`);
        console.log("home hero data", homeHeroData)
        setHomeHero(homeHeroData);

        const homeHeroSubjectData = await client.fetch(`*[_type == "homeSubjects"]`);
        setHomeHeroSubjects(homeHeroSubjectData);

        const questionAns = await client.fetch(`*[_type == "quesAns"]`);
        setQuestions(questionAns);

        const adminData = await client.fetch(`*[_type == "ajayAnurag"]`);
        setAdminInfo(adminData);

        const videoData = await client.fetch(`*[_type == "homeVideos"]`);
        console.log('video data', videoData)
        setVideo(videoData);

        const privacyData = await client.fetch(`*[_type == "privacyPolicy"]`);
        setPrivacyPolicy(privacyData);

        const termsServicesData = await client.fetch(`*[_type == "termServices"]`);
        setTermsPolicy(termsServicesData);

        const practiceQuestionData = await client.fetch(`*[_type == "practiceDetailSchema"]`);
        console.log('practice question', practiceQuestionData)
        setPracticeQuestion(practiceQuestionData);


        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{bgColor, headerFooter, practiceQuestion, notes, exams, ebooks, blogs, story, homeHero, homeHeroSubjects, questions, adminInfo,privacyPolicy, termsPolicy, video, loading, }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
