import React, { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { useParams, Link, useNavigate } from "react-router-dom";
import ScrollToTopButton from "../../../../unusableComponent/utils/scrollToTopButton";
import { Footer } from "../../../footer/Footer";
import Header from "../../../header/Header";
import {
  HeroHeaderSyllabus,
  HeroHeader,
  HeaderButton,
} from "../../HeroHeaderSyllabus";
import { urlFor } from "../../../../sanityCli";

export const NotesMainPage = () => {
  const { exams, notes, loading } = useContext(DataContext);
  const navigate = useNavigate();

  if (loading) return <></>;

  const subjectNotes = notes[0].subjects;
  const syllabus = exams[0].subExams[0].syllabus;

  console.log("sub notes", subjectNotes);

  var headerButtons = [
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} Questions`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/questions`
    ),
    new HeaderButton(
      `${exams[0].examName} ${exams[0].subExams[0].title} PYQs`,
      `/${exams[0].examName}/${exams[0].subExams[0].title}/pyqs`
    ),
  ];
  const header = new HeroHeader(
    headerButtons,
    "Subject Notes",
    syllabus.author == "Admin",
    syllabus.date
  );

  return (
    <>
      <Header />
      <div>
        {header && <HeroHeaderSyllabus props={header} />}
      </div>
      <div className="notes-subject-detail px-4 py-[5vw] pt-4 flex flex-col items-center">
        <p className="text-[#007fff] text-[2.25vw] font-semibold font-serif">
          All Subjects Notes
        </p>
        <div className="w-full flex flex-wrap px-[4vw] items-center justify-between">
          {subjectNotes &&
            subjectNotes.map((item, itemIndex) => (
              <div
                onClick={() =>
                  navigate(`/notes/${item.subjectName}`, {
                    state: { subjectNotes },
                  })
                }
                className="notes-box w-full sm:w-[45%] lg:w-[28%] flex items-center gap-2  bg-white rounded-lg mt-8 px-4 py-2 transition-all duration-500 ease-in-out shadow-xl hover:shadow-2xl hover:transform hover:-translate-y-1"
              >
                <div>
                  <img src={urlFor(item.icon)} alt={`/${item.subjectName}`} className="w-20 lg:w-[6.5vw]" /> 
                 </div>
                <div className="flex flex-col items-start">
                  <p className="text-[#2d2d2d]  text-sm lg:text-[1.5vw] lg:leading-[3vw] font-semibold ">
                    {item.subjectName}
                  </p>
                  <p className="text-gray-800 lg:text-lg font-medium font-serif">
                    {item.subHeader}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};
