import React from "react";
import { urlFor } from "../../sanityCli";
import BlockContent from "@sanity/block-content-to-react";

export const PrelimsContentBox = (contentdata) => {
  console.log("content Data", contentdata.contentData);
  return (
    
    <div className="w-full">
      {contentdata &&
        contentdata.contentData.map((section, index) => (
          <>
            <h1
              className="text-base sm:text-lg lg:text-[2vw] lg:leading-[3.5vw] font-semibold  my-2"
              style={{ color: `${section.headerColor ? section.headerColor.hex : "#030260"}` }}
            >
              {section.header}
            </h1>
            <div
              key={index}
              style={{ border: `1px solid ${section.borderColor ? section.borderColor.hex : "#2d2d2d"}` }}
              className="px-2 py-4 lg:p-5 rounded-md"
            >
              {section.contentList.map((detail, idx) => (
                <div className="flex items-start gap-1 mb-2 lg:mb-6">
                  <img
                    src={section.bulletImage && urlFor(section.bulletImage)}
                    alt="/bullet-img"
                    className="w-4 lg:w-5 mt-1 lg:mt-0"
                  />
                  <BlockContent blocks={detail.content} />
                </div>
              ))}
            </div>
          </>
        ))}
    </div>
  );
};
