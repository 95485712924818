import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../header/Header";
import { Footer } from "../footer/Footer";
import ScrollToTopButton from "../../unusableComponent/utils/scrollToTopButton";
import { urlFor } from "../../sanityCli";

import {
  HeroHeaderSyllabus,
  HeroHeader,
  HeaderButton,
} from "../reusablepages/HeroHeaderSyllabus";
import { ListContent } from "../reusableComponent/ListContent";
import Table2 from "../reusableComponent/Table2";
import { PrelimsContentBox } from "../reusableComponent/PrelimsContentBox";

const BlogDetail = () => {
  const location = useLocation();
  console.log("blog - this", location.state);
  const { path } = useParams();
  var blog = location.state.blog;
  if (!blog) return <></>;

  var headerButtons = [];

  blog.tags.map((x) =>
    headerButtons.push(new HeaderButton(x, `/blog/tag/${x}`))
  );
  var header = new HeroHeader(
    headerButtons,
    blog.header,
    blog.author,
    blog.date
  );

  return (
    <>
      <Header />
      <div className="mt-28 lg:mt-40">
        {header && <HeroHeaderSyllabus props={header} />}
      </div>

      <div className="flex items-start flex-col p-[4vw] space-y-4">
        <p className="lg:text-[2.25vw] lg:leading-[5vw] text-lg font-semibold  text-[#030260] my-4">
          {blog.intro}
        </p>
        {blog.images &&
          blog.images.map((x) => {
            return (
              <div className="w-full flex items-center justify-center bg-[#2d2d2d] rounded-lg">
                <img src={urlFor(x)} className="w-4/5" />
              </div>
            );
          })}
        {blog.items.map((i) => {
          if (i._type == "listContent") {
            return <ListContent listContents={[i]} />;
          } else if (i._type == "tableData") {
            return <Table2 title={i.title} headers={i.headers} data={i} />;
          } else if (i._type == "ContentBoxWithImageCheckbox") {
            return <PrelimsContentBox contentData={[i]} />;
          }
        })}
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default BlogDetail;

// import React from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../header/Header";
// import { Footer } from "../../footer/Footer";
// import ScrollToTopButton from "../../../utils/scrollToTopButton";
// import { RightSideBar } from "../../rightSideBar/RightSideBar";

// const BlogDetail = () => {
//   const location = useLocation();
//   const { box } = location.state || { box: {} };

//   return (
//     <>
//       <Header />
//       <div className="bg-blue-50 min-h-screen">
//         <div className="container mx-auto py-20 px-4">
//           <div className="mb-4 flex flex-col items-center space-y-4">
//             <span className="flex items-center gap-4">
//               {box.bandText &&
//                 box.bandText.map((text, i) => (
//                   <span
//                     key={i}
//                     className="w-fit bg-sky-800 text-white px-3 py-1 text-sm font-semibold rounded-md mb-2"
//                   >
//                     {text}
//                   </span>
//                 ))}
//             </span>
//             <h1 className="text-3xl font-bold mb-2">{box.heading}</h1>
//             <div className="text-blue-500 text-sm font-semibold flex items-center gap-4">
//               <span>{box.author}</span>
//               <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
//               <span>{new Date(box.date).toLocaleDateString()}</span>
//             </div>
//           </div>
//           <div className="flex items-start gap-8 my-12">
//             {box.questions && (
//               <div className="bg-white w-3/4 shadow-lg rounded-lg py-[vw] px-[2vw]">
//                 {box.questions.map((question, index) => (
//                   <div key={index} className="my-12 pb-8 border-b-2 border-sky-800">
//                     <h3 className="text-[1.75vw] text-sky-800 font-serif font-semibold mb-10">
//                       {question.title}
//                     </h3>
//                     {question.sections.map((section, i) => (
//                       <div key={i} className="mb-4">
//                         <h4 className="text-2xl text-blue-00 flex items-center justify-center font-semibold my-8">
//                           <span>{section.name}</span>
//                         </h4>
//                         <ul className="list-none list-inside">
//                           {section.questions.map((q, j) => (
//                             <li key={j} className="my-4">
//                               {q}
//                             </li>
//                           ))}
//                         </ul>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </div>
//             )}
//             <div className="w-1/4"><RightSideBar /></div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//       <ScrollToTopButton />
//     </>
//   );
// };

// export default BlogDetail;
